import Vue from "vue";
import Vuetify from "vuetify/lib";
import { VBtn, VSpacer, VIcon, VDataTable } from "vuetify/lib";
import ru from "vuetify/src/locale/ru.ts";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru"
  },
  icons: {
    iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  components: {
    VBtn,
    VSpacer,
    VIcon,
    VDataTable
  }
});
