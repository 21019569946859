<template>
  <div class="">
    <div class="white elevation-1 pa-2">
      <v-btn text @click="$router.push({ name: 'UsersNewPage' })"
        >Добавить пользователя</v-btn
      >
    </div>

    <v-data-table
      :headers="headers"
      :items="$store.state.users.items"
      :hide-default-footer="true"
      :disable-pagination="true"
      disable-sort
      class="white elevation-1 mt-2"
      @click:row="pickItem"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  name: "UsersPage",
  data() {
    return {
      headers: [
        { text: "#", value: "id", sortable: false },
        { text: "Имя", value: "name", sortable: false },
        { text: "Роль", value: "role", sortable: false }
      ],
      loading: false
    };
  },
  beforeMount() {
    this.fetchData();
  },
  methods: {
    pickItem(item) {
      this.$router.push({ name: "UsersEditPage", params: { id: item.id } });
    },
    async fetchData() {
      this.loading = true;
      try {
        await this.$store.dispatch("getUsers");
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style>
table tr td {
  cursor: pointer !important;
}
</style>
