<template>
  <div class="">
    <div v-show="!newOrder.active" class="white elevation-1 pa-2">
      <v-btn text @click="newOrder.active = true">Создать заявку</v-btn>
    </div>

    <div v-if="newOrder.active" class="white elevation-1 pa-2">
      <new-order @close="newOrder.active = false" />
    </div>

    <v-data-table
      :headers="headers"
      :items="$store.state.orders.items"
      :items-per-page="15"
      :server-items-length="$store.state.orders.totalItems"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15]
      }"
      class="white elevation-1 mt-2"
      @click:row="pickItem"
      @update:options="optionsChanged"
    ></v-data-table>
  </div>
</template>

<script>
import NewOrder from "@/components/NewOrder";

export default {
  name: "OrdersPage",
  components: {
    NewOrder
  },
  data() {
    return {
      newOrder: {
        active: false
      },
      pagination: {
        page: 1,
        itemsPerPage: 15,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 100
      },
      sorting: {
        sort: "",
        order: "asc"
      },
      totalItems: 112,
      headers: [
        { text: "#", value: "id", sortable: true },
        { text: "Серийный номер", value: "serial", sortable: false },
        { text: "Описание", value: "description", sortable: false },
        { text: "Создал", value: "userName", sortable: false },
        { text: "Кол-во меток", value: "labelCount", sortable: false },
        { text: "Дата создания", value: "createdAt", sortable: true }
      ],
      loading: false
    };
  },
  methods: {
    pickItem(item) {
      this.$router.push({ name: "OrderPage", params: { id: item.id } });
    },
    optionsChanged(options) {
      this.pagination.itemsPerPage = options.itemsPerPage;
      this.pagination.page = options.page;
      this.sorting.sort = options.sortBy.length ? options.sortBy[0] : "";
      this.sorting.order =
        options.sortDesc.length && options.sortDesc[0] ? "desc" : "asc";
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      try {
        await this.$store.dispatch("getOrders", {
          page: this.pagination.page,
          size: this.pagination.itemsPerPage,
          sort: this.sorting.sort,
          order: this.sorting.order
        });
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  }
};
</script>

<style>
table tr td {
  cursor: pointer !important;
}
</style>
