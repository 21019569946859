import Vue from "vue";
import VueRouter from "vue-router";
import OrdersPage from "../views/OrdersPage.vue";
import LoginPage from "../views/LoginPage.vue";
import LabelSearchPage from "../views/LabelSearchPage.vue";
import UsersPage from "@/views/UsersPage";
import UsersNewPage from "@/views/UsersNewPage";
import Store from "@/store";
import OrderPage from "@/views/OrderPage";
import HaspPage from "@/views/HaspPage";
import ForbiddenPage from "../views/ForbiddenPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/orders",
    name: "OrdersPage",
    component: OrdersPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user"]
    }
  },
  {
    path: "/users",
    name: "UsersPage",
    component: UsersPage,
    meta: {
      authRequired: true,
      roles: ["admin", "support"]
    }
  },
  {
    path: "/users/new",
    name: "UsersNewPage",
    component: UsersNewPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user"]
    }
  },
  {
    path: "/users/:id",
    name: "UsersEditPage",
    component: UsersNewPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user"]
    }
  },
  {
    path: "/labels/search",
    name: "LabelSearchPage",
    component: LabelSearchPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user", "support"]
    }
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: {
      authRequired: false,
      roles: []
    }
  },
  {
    path: "/forbidden",
    name: "ForbiddenPage",
    component: ForbiddenPage,
    meta: {
      authRequired: false,
      roles: []
    }
  },
  {
    path: "/orders/:id",
    name: "OrderPage",
    component: OrderPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user"]
    }
  },
  {
    path: "/hasp/info",
    name: "HaspPage",
    component: HaspPage,
    meta: {
      authRequired: true,
      roles: ["admin", "user"]
    }
  }
];

const router = new VueRouter({
  routes
});

const defaultPages = {
  admin: "OrdersPage",
  user: "OrdersPage",
  support: "LabelSearchPage"
};

router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    if (Store.getters.isAuthenticated) {
      next({ name: defaultPages[Store.getters.userRole] });
    } else {
      next({ name: "LoginPage" });
    }
    return;
  }

  if (Store.getters.isAuthenticated) {
    if (
      to.meta.authRequired &&
      Array.isArray(to.meta.roles) &&
      !to.meta.roles.includes(Store.getters.userRole)
    ) {
      next({ name: "ForbiddenPage" });
      return;
    }
  } else {
    if (to.meta.authRequired) {
      next({ name: "LoginPage" });
      return;
    }
  }

  next();
});
export default router;
