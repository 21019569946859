<template>
  <div class="pa-2">
    <div class="title">Новая заявка</div>
    <div class="">
      <v-text-field
        v-model.trim="item.labelCount"
        :disabled="loading"
        :error-messages="validation.labelCount"
        label="Кол-во выпускаемых ключей"
      />
      <v-textarea
        v-model.trim="item.description"
        :disabled="loading"
        :error-messages="validation.description"
        counter
        label="Описание"
      ></v-textarea>
    </div>
    <div class="layout">
      <v-spacer />
      <v-btn :disabled="loading" text color="error" @click="onCancel"
        >Отмена
      </v-btn>
      <v-btn :loading="loading" text color="success" @click="onSave"
        >Создать
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewOrder",
  data() {
    return {
      loading: false,
      item: {
        labelCount: 10,
        description: ""
      },
      validation: {
        description: [],
        labelCount: []
      }
    };
  },
  methods: {
    onCancel() {
      this.$emit("close");
    },
    validate() {
      this.validation.description = [];
      this.validation.labelCount = [];

      let hasError = false;
      if (this.item.description.length > 255) {
        this.validation.description = ["Максимальная длина поля 255 символов"];
        hasError = true;
      }
      if (
        isNaN(parseInt(this.item.labelCount)) ||
        parseInt(this.item.labelCount) < 1 ||
        parseInt(this.item.labelCount) > 100000 ||
        parseInt(this.item.labelCount).toString().length !==
          this.item.labelCount.toString().length
      ) {
        this.validation.labelCount = [
          "Значение должно быть числом от 1 до 100000"
        ];
        hasError = true;
      }
      return !hasError;
    },
    async onSave() {
      if (!this.validate()) {
        return;
      }
      this.loading = true;
      try {
        let orderId = await this.$store.dispatch("createNewOrder", this.item);
        this.$router.push({ name: "OrderPage", params: { id: orderId } });
      } catch (e) {
        console.log("new order saving", e);
      }
      this.loading = false;
      this.$emit("close", this.item);
    }
  }
};
</script>

<style scoped></style>
