<template>
  <div class="">
    <div class="layout wrap">
      <div class="flex xs4 px-1">
        <v-text-field
          label="Имя"
          v-model="item.name"
          :error-messages="errors.name ? ['Заполните поле'] : []"
        />
      </div>
      <div class="flex xs4 px-1">
        <v-select
          v-model="item.role"
          label="Роль"
          :items="[
            { code: 'user', text: 'Пользователь' },
            { code: 'admin', text: 'Администратор' },
            { code: 'support', text: 'Support' }
          ]"
          item-value="code"
          item-text="text"
        />
      </div>
    </div>
    <div class="layout wrap">
      <div class="flex xs4 px-1">
        <v-text-field
          v-model="item.login"
          label="Логин"
          :error-messages="errors.login ? ['Заполните поле'] : []"
        />
      </div>
      <div class="flex xs4 px-1">
        <v-text-field v-model="item.password" label="Пароль" type="password" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserForm",
  props: ["item"],
  data() {
    return {
      errors: {
        name: null,
        login: null
      }
    };
  },
  methods: {
    validateAll() {
      this.errors.name = this.item.name.trim().length === 0;
      this.errors.login = this.item.login.trim().length === 0;
      return !(this.errors.name || this.errors.login);
    }
  }
};
</script>
