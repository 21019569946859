<template>
  <div class="">
    <div class="white elevation-1 pa-2">
      <div class="title">
        <span v-show="mode === 'add'">Новый пользователь</span>
        <span v-show="mode === 'edit'">Обновить пользователя</span>
      </div>
    </div>
    <div class="white elevation-1 pa-2 mt-2">
      <user-form ref="form" :item="item" />
    </div>
    <div v-show="mode !== 'none'" class="white elevation-1 pa-2 mt-2 layout">
      <v-btn
        v-show="mode === 'edit'"
        :loading="loading"
        text
        @click="deleteUser"
        color="red"
        >Удалить</v-btn
      >
      <v-spacer />
      <v-btn :loading="loading" text @click="saveUser" color="green">{{
        this.mode === "add" ? "Добавить" : "Обновить"
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import UserForm from "@/components/UserForm";
import { mapErrorCode } from "@/utils";
export default {
  name: "UsersNew",
  components: {
    UserForm
  },
  data() {
    return {
      loading: false,
      mode: "none",
      item: {
        name: "",
        role: "user",
        login: "",
        password: ""
      }
    };
  },
  methods: {
    async deleteUser() {
      this.loading = true;
      try {
        if (this.mode === "edit") {
          await this.$store.dispatch("deleteUser", this.item);
        }
        this.$router.push({ name: "UsersPage" });
      } catch (e) {
        console.log("saveUser", e);
      }
      this.loading = false;
    },
    async saveUser() {
      if (!this.$refs.form.validateAll()) {
        return false;
      }
      this.loading = true;
      try {
        if (this.mode === "add") {
          await this.$store.dispatch("createUser", this.item);
        }
        if (this.mode === "edit") {
          await this.$store.dispatch("updateUser", this.item);
        }
        this.$router.push({ name: "UsersPage" });
      } catch (e) {
        console.log("saveUser", e);
        this.$store.dispatch("addMessage", {
          text: mapErrorCode(e.error),
          type: "error"
        });
      }
      this.loading = false;
    },
    async fetchUser() {
      this.loading = true;
      try {
        this.item = await this.$store.dispatch(
          "getUser",
          this.$route.params.id
        );
      } catch (e) {
        console.log("fetchUser", e);
      }
      this.loading = false;
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.mode = "edit";
      this.fetchUser();
    } else {
      this.mode = "add";
    }
  }
};
</script>

<style scoped></style>
