<template>
  <div class="">
    <div class="white elevation-1 pa-2 layout align-center">
      <v-text-field
        v-model="searchValue"
        :loading="loading"
        prepend-icon="mdi-magnify"
        label="Введите номер метки"
        @keydown.enter="search"
      />
      <v-btn
        :loading="loading"
        :disabled="!this.searchValue"
        text
        @click="search"
        class="ml-2"
        >Поиск</v-btn
      >
    </div>
    <div v-if="!searchInfo && hasSearched" class="white elevation-1 pa-2 mt-2">
      <div class="text-center body-1">Ничего не найдено</div>
    </div>
    <div v-if="!!searchInfo" class="white elevation-1 pa-2 mt-2">
      <h4>Информация о метке</h4>
      <div class="pa-2">
        <span>Code: </span><span>{{ searchInfo.code }}</span>
      </div>
      <div class="pa-2">
        <span>W64Hex8: </span><span>{{ searchInfo.w64hex8 }}</span>
      </div>
      <div class="pa-2">
        <span>Статус: </span
        ><span>{{ searchInfo.status ? "Активирован" : "Неактивирован" }}</span>
      </div>
      <div class="pa-2" v-if="$store.getters.userRole !== 'support'">
        <span>Заявка: </span
        ><router-link
          :to="{ name: 'OrderPage', params: { id: searchInfo.orderId } }"
          >Перейти к заявке</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersPage",
  data() {
    return {
      searchValue: null,
      loading: false,
      searchInfo: null,
      hasSearched: false
    };
  },
  methods: {
    async search() {
      if (!this.searchValue.trim()) {
        return;
      }
      this.loading = true;
      try {
        this.searchInfo = await this.$store.dispatch(
          "getLabelInfo",
          this.searchValue
        );
        this.hasSearched = true;
      } catch (e) {
        console.log("search", e);
      }
      this.loading = false;
    }
  }
};
</script>
