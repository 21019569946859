<template>
  <div>
    <div
      v-for="hasp in $store.state.haspInfo"
      :key="hasp.gid"
      class="white elevation-1 pa-2"
    >
      <div class="title text-center">HASP #{{ hasp.gid }}</div>
      <div class="pa-2 layout">
        <div class="flex xs12 sm4">
          <span class="pr-2">Первый номер:</span>{{ hasp.from }}
        </div>
        <div class="flex xs12 sm4">
          <span class="pr-2">Последний номер:</span>{{ hasp.till }}
        </div>
        <div class="flex xs12 sm4">
          <span class="pr-2">Свободных меток:</span>{{ hasp.free }}
        </div>
      </div>
    </div>
    <div class="white elevation-1 pa-4 mt-2">
      <span>Всего свободных меток: {{ getTotalFree }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderPage",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async fetchHasp() {
      this.loading = true;
      try {
        await this.$store.dispatch("getHaspInfo");
      } catch (e) {
        console.log("fetch hasp info", e);
      }
      this.loading = false;
    }
  },
  computed: {
    getTotalFree() {
      return this.$store.state.haspInfo.reduce((p, c) => {
        return p + c.free;
      }, 0);
    }
  },
  beforeMount() {
    this.fetchHasp();
  }
};
</script>
