import Vue from "vue";
import Vuex from "vuex";
import Router from "@/router";
import moment from "moment";
import axios from "axios";

Vue.use(Vuex);

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export default new Vuex.Store({
  getters: {
    isUserAdmin(state) {
      return state.user.role === "admin";
    },
    isUserAdminOrDefault(state) {
      return ["admin", "user"].includes(state.user.role);
    },
    userRole(state) {
      return state.user.role;
    },
    isAuthenticated(state) {
      return !!state.authToken;
    }
  },
  state: {
    messages: [],
    authToken: sessionStorage.getItem("authToken"),
    user: {
      name: sessionStorage.getItem("userName"),
      role: sessionStorage.getItem("userRole")
    },
    orders: {
      items: [],
      totalItems: 0
    },
    users: {
      items: [],
      totalItems: 0
    },
    haspInfo: [],
    labelSearchInfo: {}
  },
  mutations: {
    clearAuth(state) {
      state.authToken = null;
      state.user.name = null;
      state.user.role = null;
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("userRole");
    },
    setSession(state, { token, name, role }) {
      state.authToken = token;
      state.user.name = name;
      state.user.role = role;
      sessionStorage.setItem("authToken", token);
      sessionStorage.setItem("userName", name);
      sessionStorage.setItem("userRole", role);
    },
    setOrders(state, { items, totalItems }) {
      state.orders.items =
        items.map(i => {
          i.createdAt = moment(i.createdAt * 1000).format("DD.MM.YYYY hh:mm");
          return i;
        }) || [];
      state.orders.totalItems = totalItems;
    },
    setUsers(state, items) {
      state.users.items = items.map(i => {
        switch (i.role) {
          case "admin":
            i.role = "Администратор";
            break;
          case "support":
            i.role = "Support";
            break;
          default:
            i.role = "Пользователь";
        }
        return i;
      });
    },
    setEditOrder(state, order) {
      state.editingOrder = order;
    },
    createMessage(state, message) {
      state.messages.push(message);
    },
    removeMessage(state, id) {
      state.messages = state.messages.filter(i => i.id !== id);
    },
    setHaspInfo(state, info) {
      state.haspInfo = info;
    }
  },
  actions: {
    async addMessage({ commit }, { text, type = "info", timeout = 3000 }) {
      let id = getRandomInt(1, 999999);
      commit("createMessage", { id, text, type, timeout });
      if (timeout > 0) {
        setTimeout(() => {
          commit("removeMessage", id);
        }, timeout);
      }
    },
    async getOrderFile({ state }, id) {
      try {
        // let result = await fetch(`/api/v0/orders/${id}/file`, {
        //   method: "GET",
        //   headers: {
        //     Authorization: `Bearer ${state.authToken}`
        //   }
        // });
        // return await result.blob();

        let result = await axios.request({
          url: `/api/v0/orders/${id}/file`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          },
          responseType: "arraybuffer",
          maxContentLength: Infinity,
          timeout: 3600 * 1000
        });
        return new Blob([result.data], { type: "application/zip" });
      } catch (e) {
        console.log("getOrderFile", e);
      }
    },
    async deleteUser({ state }, { id }) {
      try {
        await fetch(`/api/v0/users/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
      } catch (e) {
        console.log("deleteUser", e);
      }
    },
    async updateUser({ state }, { id, name, role, login, password }) {
      const response = await fetch(`/api/v0/users/${id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${state.authToken}`
        },
        body: JSON.stringify({ name, role, login, password })
      });
      if (!response.ok) throw await response.json();
    },
    async createUser({ state }, { name, role, login, password }) {
      let response = await fetch("/api/v0/users", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.authToken}`
        },
        body: JSON.stringify({ name, role, login, password })
      });
      const body = await response.json();

      if (!response.ok) {
        throw body;
      }
      return body.result;
    },
    async getUser({ state }, id) {
      try {
        let r = await fetch(`/api/v0/users/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        return (await r.json()).result;
      } catch (e) {
        console.log("createNewOrder", e);
      }
    },
    async getLabelInfo({ state }, code) {
      try {
        let r = await fetch(`/api/v0/labels/search?code=${code}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        return (await r.json()).result;
      } catch (e) {
        console.log("createNewOrder", e);
      }
    },
    async updateOrder({ state }, { id, description }) {
      try {
        await fetch(`/api/v0/orders/${id}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          },
          body: JSON.stringify({ description })
        });
      } catch (e) {
        console.log("createNewOrder", e);
      }
    },
    async createNewOrder({ state }, { labelCount, description }) {
      try {
        let response = await fetch("/api/v0/orders", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          },
          body: JSON.stringify({ labelCount: +labelCount, description })
        });
        return (await response.json()).result;
      } catch (e) {
        console.log("createNewOrder", e);
      }
    },
    async getOrder({ state }, orderId) {
      try {
        let response = await fetch(`/api/v0/orders/${orderId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        return (await response.json()).result;
      } catch (e) {
        console.log("getOrder", e);
      }
    },
    async getOrderShort({ state }, orderId) {
      try {
        let response = await fetch(`/api/v0/orders/${orderId}/short`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        return (await response.json()).result;
      } catch (e) {
        console.log("getOrderShort", e);
      }
    },
    async getHaspInfo({ commit, state }) {
      try {
        let response = await fetch(`/api/v0/hasp/info`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });
        commit("setHaspInfo", (await response.json()).result);
      } catch (e) {
        console.log("getHaspInfo", e);
      }
    },

    async getOrders({ commit, state }, { page, size, sort, order }) {
      try {
        let response = await fetch(
          `/api/v0/orders?page=${page}&size=${size}&sort=${sort}&order=${order}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${state.authToken}`
            }
          }
        );

        let result = (await response.json()).result;

        result.items = result.items.map(i => {
          let date = new Date(i.createdAt * 1000);
          i.serial =
            date.getFullYear().toString() +
            (date.getMonth() + 1).toString() +
            i.id.toString().padStart(6, "0");
          return i;
        });

        commit("setOrders", result);
      } catch (e) {
        console.log("getOrders", e);
      }
    },
    async getUsers({ commit, state }) {
      try {
        let response = await fetch("/api/v0/users", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${state.authToken}`
          }
        });

        commit("setUsers", (await response.json()).result);
      } catch (e) {
        console.log("getOrders", e);
      }
    },
    async login({ commit }, { login, password }) {
      try {
        let response = await fetch(
          `/api/v0/login?login=${login}&password=${password.b64encode()}`,
          {
            method: "POST"
          }
        );
        let token = (await response.json()).result.token;

        response = await fetch(`/api/v0/users/me`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        let user = (await response.json()).result;

        await commit("setSession", { token, ...user });
        if (user.role === "support") {
          Router.push({ name: "LabelSearchPage" });
        } else {
          Router.push({ name: "OrdersPage" });
        }
        return true;
      } catch (e) {
        console.log("login error", e);
      }
      return false;
    },
    async logout({ commit }) {
      await commit("clearAuth");
      Router.push({ name: "LoginPage" });
    }
  },
  modules: {}
});
