<template>
  <div>
    <div class="white elevation-1 pa-2 mt-2">
      <v-btn text @click="$router.push({ name: 'OrdersPage' })">Назад</v-btn>
    </div>
    <div class="white elevation-1 pa-2 mt-2">
      <div class="title text-center">Заявка #{{ this.orderCode }}</div>
      <div class="pa-2">
        <div class="layout wrap">
          <div class="flex xs6">
            <span>Создал: </span>
            <span class="body-1 font-weight-bold">{{ order.userName }}</span>
          </div>
          <div class="flex xs6 text-right">
            <span class="body-2">{{ orderDate }}</span>
          </div>
        </div>
        <div>
          <v-textarea
            v-model.trim="order.description"
            :disabled="loading"
            :error-messages="validation.description"
            counter
            label="Описание"
          ></v-textarea>
        </div>
        <div v-if="order.fileStatus">
          <v-btn
            :loading="isPdfDownloading"
            :disabled="order.fileStatus === 'file_is_generating'"
            text
            color="red"
            @click="downloadPdf"
          >
            <v-icon>mdi-file-pdf</v-icon>
            <span v-show="order.fileStatus === 'file_is_generating'"
              >Создается Pdf файл</span
            >
            <span v-show="order.fileStatus === 'file_not_exists'"
              >Создать Pdf файл</span
            >
            <span v-show="order.fileStatus === 'file_exists'"
              >Скачать Pdf файл</span
            >
          </v-btn>
        </div>
      </div>
    </div>
    <div class="white elevation-1 pa-2 mt-2">
      <v-data-table
        v-if="order && order.labels"
        :items="order.labels"
        :headers="headers"
      ></v-data-table>
    </div>

    <div class="white elevation-1 pa-2 mt-2 layout">
      <v-spacer />
      <v-btn :loading="loading" text @click="saveOrder" color="green"
        >Сохранить
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderPage",
  data() {
    return {
      isPdfDownloading: false,
      loading: false,
      headers: [
        { text: "Code", value: "code", sortable: false },
        { text: "W64Hex8", value: "w64hex8", sortable: false }
      ],
      order: {
        id: 0,
        userName: null,
        description: null,
        createdAt: 0,
        labels: [],
        fileStatus: null
      },
      validation: {
        description: []
      }
    };
  },
  computed: {
    orderCode() {
      if (this.order) {
        let date = new Date(this.order.createdAt * 1000);
        return (
          date.getFullYear().toString() +
          (date.getMonth() + 1).toString() +
          this.order.id.toString().padStart(6, "0")
        );
      }
      return null;
    },
    orderDate() {
      return this.order
        ? moment(this.order.createdAt * 1000).format("DD.MM.YYYY hh:mm")
        : "";
    }
  },
  methods: {
    downloadFile(data, fileName) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const blobUrl = window.URL.createObjectURL(data);
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }, 0);
    },
    async downloadPdf() {
      this.isPdfDownloading = true;
      try {
        let blob = await this.$store.dispatch("getOrderFile", this.order.id);
        if (blob) {
          this.downloadFile(blob, `order-${this.order.id}.zip`);
        }
        this.updateOrder();
      } catch (e) {
        console.log("download pdf error", e);
      }
      this.isPdfDownloading = false;
    },
    async updateOrder() {
      this.loading = true;
      try {
        let order = await this.$store.dispatch(
          "getOrderShort",
          this.$route.params.id
        );
        this.order.fileStatus = order.fileStatus;
      } catch (e) {
        console.log("fetch order", e);
      }
      this.loading = false;
    },
    validate() {
      this.validation.description = [];

      let hasError = false;
      if (this.order.description && this.order.description.length > 255) {
        this.validation.description = ["Максимальная длина поля 255 символов"];
        hasError = true;
      }
      return !hasError;
    },
    async saveOrder() {
      if (!this.validate()) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch("updateOrder", {
          id: this.order.id,
          description: this.order.description
        });
      } catch (e) {
        console.log("saveOrder", e);
      }
      this.loading = false;
    },
    async fetchOrder() {
      this.loading = true;
      try {
        this.order = await this.$store.dispatch(
          "getOrder",
          this.$route.params.id
        );
      } catch (e) {
        console.log("fetch order", e);
      }
      this.loading = false;
    }
  },
  beforeMount() {
    this.fetchOrder();
  }
};
</script>
